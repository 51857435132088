<!--订单管理-->
<template>
  <div class="mbox">
    <h2>订单管理</h2>
    <div class="search-div">
      订单状态：
      <el-select v-model="orderStatus" clearable placeholder="订单状态" @change="orderStatusChange"> 
        <el-option label="不限" value=""/>   
        <el-option label="待审批" value="0"/>
        <el-option label="审批通过（待服务）" value="1"/>
        <el-option label="服务中" value="2"/>
        <el-option label="服务完成（待评价）" value="3"/>
        <el-option label="审批不通过" value="-1"/>
        <el-option label="已取消" value="-2"/>
        <el-option label="已终止" value="-3"/>        
      </el-select>
    </div>
    <el-table v-loading="tableLoading" :data="orderData" highlight-current-row stripe border
        style="width: 100%" 
        header-row-class-name="header-row" header-cell-class-name="header-cell">
      <el-table-column type="selection" width="40"></el-table-column>
      <!-- <el-table-column type="index" label="序" width="40"></el-table-column> -->
      <el-table-column label="资源名称">
        <template #default="scope">
          {{scope.row.restName}}      
        </template>
      </el-table-column>
      <el-table-column prop="username" label="所有人" width="150"></el-table-column>
      <el-table-column label="状态" width="80">
        <template #default="scope">
          {{scope.row.status}}
        </template>
      </el-table-column>
      <el-table-column prop="createName" label="申请人" width="120"></el-table-column>  
      <el-table-column prop="createDate" label="申请时间" sortable width="150"></el-table-column>      
      <el-table-column label="操作" width="150">
      <template #default="scope">
        <el-button type="primary" plain round size="small" @click="editInfo(scope.row.id)">评价</el-button>
        <el-button type="primary" plain round size="small" @click="addComplaint(scope.row.id)">投诉</el-button>
      </template>
    </el-table-column>     
    </el-table>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
      :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
    </el-pagination>
  </div>
  <!-- 订单投诉意见 -->
  <el-dialog title="投诉意见" v-model="dialogComplaint" :close-on-click-modal="false" :before-close="closeComplaint">
    <el-form ref="formRef" :model="form" :rules="rules" label-width="130px">      
      <el-form-item label="投诉意见" prop="opinion">
        <el-input v-model="form.opinion" type="textarea" maxlength="200" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeComplaint">关 闭</el-button>
        <el-button @click="resetComplaint">重 填</el-button>
        <el-button type="primary" @click="saveComplaint">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'useredit',
  data () {
    return {
      userId: '',
      tableLoading: false,
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      orderData: [],
      orderStatus: '',
      dialogComplaint: false,
      form: { 
        // 申请记录id
        recordId: '',
        // 投诉意见
        opinion: ''
      },
      rules: {        
         opinion: [
          { required: true, message: '请输入投诉意见', trigger: 'blur' }
          // { min: 10, max: 200, message: '长度在 10 到 200 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () { 
    // 获取本地信息 用户登录成功后取用户名
    const ui = this.$api.ui    
    if (ui !== null) {
      this.userId = ui.uid
    } else {
      this.$router.push({ path: '/login' })            
    }
    this.getListData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData('')
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    orderStatusChange(val) {
      this.getListData(val)
    },
    async getListData (status) {
      this.tableLoading = true
      // status订单状态，0待审批，1审批通过（待服务），2服务中，3服务完成（待评价），-1审批不通过，-2已取消，-3已终止
      const params = '?createBy='+ this.userId +'&status=' + status + '&size='+ this.pagesize + '&current=' + this.currentPage
      const { data: ret } = await this.$http.get(this.$api.orderlist + params)      
      console.log('Listret=', ret)
      if (ret.code === 1) {
        this.orderData = ret.data.records
        this.tableTotal = ret.data.total
        console.log('rows=', ret.data.records)
      } else {
        this.$message.error(ret.msg)        
      } 
      this.tableLoading = false    
    },
    addComplaint (id) {
      this.form.recordId = id
      this.dialogComplaint = true
    },
    async saveComplaint () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          console.log('this.form=', this.form)
          const { data: ret } = await this.$http.post1(this.$api.complaintAdd, this.form) 
          // console.log('retOK=', ret)
          if (ret.code === 1) {
            this.$message.success(ret.msg)
            this.closeComplaint()
          } else {
            this.$message.error(ret.msg)
          }
        } else {
          this.$message.error('验证没通过。')
          return false
        }
      })
    },
    resetComplaint () {
      console.log('resetComplaint')
      this.$refs.formRef.resetFields()
    },
    closeComplaint () {
      this.form.recordId = ''
      this.$refs.formRef.resetFields()
      this.dialogComplaint = false
    },
    
  }
}
</script>

<style scoped>

</style>